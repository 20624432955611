import Typography from "@mui/material/Typography"
import CourseSection from "../components/CourseSection"
import PageBox from "../components/PageBox"
import SectionBox from "../components/SectionBox"
import { gelterkinden, gsteigtobel, kappel } from "../data/locations"
import { christian, monika } from "../data/persons"
import { ICourse } from "../interfaces/ICourse"

export default function ViaIntegralis() {
  const courses: ICourse[] = [
    {
      title: "Schweigen am Gsteigtobel 2. Halbjahr 2024",
      description: [
        "Das «Schweigen Am Gsteigtobel 1» dauert eine Stunde. Nach einem ersten Teil, wo wir uns bewegen und bewusst unsern Körper spüren, gibt es eine Einleitung mit Hinweisen zum Sitzen. Es folgt ein geistlicher Impuls mit einem Text, Gebet oder Lied. Danach sitzen wir im Schweigen. Die Schweigezeit wird mit einem Segen oder Text abgeschlossen. Einsteigen ist jederzeit möglich. ",
      ],
      type: "Lektion",
      location: gsteigtobel,
      person: [monika, christian],
      price: { paymentType: "voluntary", info: "Auf freiwilliger Basis, Richtpreis: Fr 15.- pro Abend" },
      occurances: [
        { start: new Date(2024, 7, 15, 18), end: new Date(2024, 7, 15, 19) },
        { start: new Date(2024, 7, 29, 18), end: new Date(2024, 7, 29, 19) },
        { start: new Date(2024, 8, 12, 18), end: new Date(2024, 8, 12, 19) },
        { start: new Date(2024, 9, 17, 18), end: new Date(2024, 9, 17, 19) },
        { start: new Date(2024, 9, 31, 18), end: new Date(2024, 9, 31, 19) },
        { start: new Date(2024, 10, 14, 18), end: new Date(2024, 10, 14, 19) },
        { start: new Date(2024, 10, 28, 18), end: new Date(2024, 10, 28, 19) },
        {
          start: new Date(2024, 11, 19, 18),
          end: new Date(2024, 11, 19, 19),
          info: "anschliessend Gespräche und Apéro",
        },
      ],
    },
    {
      title: "Tage der Stille Sonnenhof",
      description: [],
      type: "Retreat im Schweigen",
      location: gelterkinden,
      person: [christian, monika],
      price: {
        amount: "130 - 180",
        paymentType: "cash",
        info: "plus Kosten für Übernachtung mit Vollpension",
      },
      occurances: [
        { start: new Date(2024, 9, 18, 17, 0), end: new Date(2024, 9, 20, 13, 30) },
        { start: new Date(2025, 3, 25, 17, 0), end: new Date(2025, 3, 27, 13, 30) },
        { start: new Date(2025, 9, 24, 17, 0), end: new Date(2025, 9, 26, 13, 30) },
      ],
      externalRegistration: "sonnenhof@grandchamp.ch / 061 981 11 12",
    },
    {
      title: "Tage der Stille Kloster Kappel 2024",
      description: [],
      type: "Retreat im Schweigen",
      location: kappel,
      person: [christian, monika],
      price: {
        amount: "260",
        paymentType: "cash",
        info: "plus Kosten für Übernachtung mit Vollpension",
      },
      occurances: [{ start: new Date(2024, 4, 31, 18, 0), end: new Date(2024, 5, 2, 13, 30) }],
      externalRegistration: "www.klosterkappel.ch / 044 764 88 30",
    },
  ]

  return (
    <PageBox title="Via Integralis" image="/images/Via_Integralis2.jpg">
      <SectionBox>
        <Typography variant="h2">Was ist Via Integralis?</Typography>
        <Typography variant="body1">
          Die via integralis ist eine Kontemplationsschule für Suchende unserer Zeit, die Sehnsucht nach eigener
          spiritueller Erfahrung haben. Diese Form der Kontemplation vermittelt einerseits das Herzstück aus der
          Zen-Buddhistischen Tradition, nämlich Zazen, das Sitzen in Stille. Anderseits verhilft uns die Beschäftigung
          mit früheren und zeitgenössischen christlichen Mystikerinnen und Mystikern unsere eigenen Erfahrungen in der
          Meditation zu deuten und zu verstehen. Durch Einbezug verschiedener Erkenntnisse der modernen Psychologie und
          der Bewusstseinsforschung sucht die via integralis nach dem adäquaten Ausdruck für eine lebendige, alltagsnahe
          und angewandte Spiritualität.
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="h2">Woher kommt die Via Integralis?</Typography>
        <Typography variant="body1">
          Die Lassalle Kontemplationsschule wurde von Zen-Meisterin Pia Gyger und Zen-Meister P. Niklaus Brantschen (SJ)
          entwickelt als Frucht ihres interreligiösen Wegs. Dieser Schulungsweg verbindet die christliche Mystik mit der
          Übung des Zazen, der gegenstandslosen Schweigemeditation des Zenbuddhismus.
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="h2">Unsere Form</Typography>
        <Typography variant="body1">
          In unsern Angeboten verbinden wir das Sitzen in der Stille mit der Meditation in Bewegung. Auf diese Weise
          wird der Körper bewusst miteinbezogen. Die Konzentrationsfähigkeit steigt.
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="h3">Meditationen</Typography>
        <Typography variant="body1">
          Nach einem ersten Teil, wo wir uns bewegen und bewusst unsern Körper spüren, gibt es eine Einleitung mit
          Hinweisen zum Sitzen. Es folgt ein geistlicher Impuls mit einem Text, Gebet oder Lied. Danach sitzen wir im
          Schweigen. Die Schweigezeit wird mit einem Segen oder Text abgeschlossen.
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="h3">Tage der Stille</Typography>
        <Typography variant="body1">
          In unseren Tagen der Stille kannst du vertieft ins Schweigen eintauchen. In den Einheiten der Meditation
          erlernst du die Grundformen von Shibashi-Qi-Gong. Dank dem gemeinsamen Schweigen erleben wir uns als Gruppe
          verbunden und getragen.
        </Typography>
      </SectionBox>

      <SectionBox>
        <Typography variant="body1">
          Wir freuen uns darauf, dich auf deinem Weg zu einer persönlichen und tiefen Spiritualität zu begleiten!
        </Typography>
      </SectionBox>

      <CourseSection courses={courses}></CourseSection>
    </PageBox>
  )
}
